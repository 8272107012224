import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlaidLink from 'react-plaid-link'

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody, Modal } from '../../components/layouts'


import {
	Button,
	Image,
	StripeCheckout,
	Loader,
	Link
} from '../../components/ui'

import {
	linkBank, resetBankState
} from '../../factory'
import {PLAID_ENV} from '../../factory/types'

import Layout from '../../components/layout'

import theme from '../../theme/content'
import {getUrlVars} from '../../util'
import { navigate } from '@reach/router'


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			has_chargable_accounts: false,
			showError: false,
			from_register: false,
		}

		this.setChargeable = this.setChargeable.bind(this)
		this.handleOnSuccess = this.handleOnSuccess.bind(this)
		this.setRegister = this.setRegister.bind(this)
	}

	componentDidMount() {
		this.props.resetBankState()
		this.setChargeable(getUrlVars().chargeable === 'true')
		this.setRegister(getUrlVars().from_register === 'true')
	}

	setChargeable(has_chargable_accounts) {
		this.setState({has_chargable_accounts})
	}

	setRegister(from_register) {
		this.setState({from_register})
	}

	handleOnSuccess(plaid_token, _) {
		this.props.linkBank(plaid_token, this.props.token, ()=>navigate('/register/roundUpAccount?from_register=true'), ()=> this.setState({showError: true}))
	}


	handleOnExit() {
		// handle the case when your user exits Link
	}

	render() {
		const {bank_pending, error} = this.props
		const {has_chargable_accounts, showError, from_register} = this.state
		let title = 'Link Account'
		if(!from_register) {
			if(has_chargable_accounts) {
				title = 'Link Another Account'
			} else {
				title = "Link Funding Source"
			}
		}

		return (
			<Layout>
				<Container>
					<PhoneBody title={title}>
						<Flex column style={{alignItems: 'center'}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2.png"}
								width={'125px'}
								marginTop={'10px'}
								height={'auto'}
								marginBottom={"0px"}
							/>
							<Heading as="h1" fontSize={'22px'}>Link a Funding Source</Heading>
							<Flex row alignItems={"center"} width={'80%'} margin={'auto'} marginBottom={15}>
								<Flex column alignItems={'center'} textAlign={'center'} marginRight={'10px'}>
									<Paragraph style={{marginBottom: 0, fontWeight: 700, width: 75}}>Option</Paragraph>
									<div style={{width: 50, height: 50, background: theme.colors.sunglow, borderRadius: 25, marginTop: 0 }}>
										<Heading color={'white'} height={"50px"} width={'50px'}textAlign={'center'} marginTop={'10px'}>1</Heading>
									</div>
								</Flex>
								<Paragraph style={{height: '100%', }}>
                        Link a <strong>checking account.</strong> It allows us to send the most money to your nonprofit.
								</Paragraph>
							</Flex>
							<PlaidLink
								clientName="Flourish"
								env={PLAID_ENV}
								product={"auth"}
								publicKey="d0d722e2447e775a33bc590c6687ac"
								onExit={this.handleOnExit}
								style={{borderRadius: '50px', width: 250, height: '2.5em', fontFamily: 'work-sans', background: theme.colors.sunglow, fontWeight: 900}}
								onSuccess={this.handleOnSuccess}>
								<Paragraph>Link A Checking Account</Paragraph>
							</PlaidLink>
							<Flex row alignItems={"center"} justifyContent={'center'} width={'80%'} margin={'5px auto'}>
								<div style={{height: 2, width: '25%', background: theme.colors.mineShaft}}/>
								<Heading as="h4" margin={'25px 15px'} fontSize={'25px'} textAlign={'center'}>OR</Heading>
								<div style={{height: 2, width: '25%', background: theme.colors.mineShaft}}/>
							</Flex>
							<Flex row alignItems={"center"} width={'80%'} margin={'auto'} marginBottom={15}>
								<Flex column alignItems={'center'} textAlign={'center'} marginRight={'10px'}>
									<Paragraph style={{marginBottom: 0, fontWeight: 700, width: 75}}>Option</Paragraph>
									<div style={{width: 50, height: 50, background: theme.colors.sunglow, borderRadius: 25, marginTop: 0 }}>
										<Heading color={'white'}  height={"50px"} width={'50px'}textAlign={'center'} marginTop={'10px'}>2</Heading>
									</div>
								</Flex>
								<Paragraph style={{height: '100%', }}>
                        Link a <strong>credit card</strong>, but it can be more expensive for your cause.
								</Paragraph>
							</Flex>
							<Modal
								containterStyle={{width: '95%', maxWidth: 400}}
								button={{text: "Link a Credit Card", style: {width: "250px", maxWidth: '250px', background: 'white', border: `2px solid ${theme.colors.sunglow}`}}}
							>
								<div style={{width: '80%'}}>
									<Heading as={"h1"} fontSize={'24px'}>Please enter your credit card:</Heading>
									<StripeCheckout redirect={'/register/roundUpAccount/?from_register=true'}/>
								</div>
							</Modal>
						</Flex>
						{bank_pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Loader/>
									<Heading as="h1" fontSize={24}>Linking Account...</Heading>
								</div>
							</div>
						</div>
						}
						{showError && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Heading as="h1" fontSize={24}>Uh oh!</Heading>
									<Paragraph>{error}</Paragraph>
									<Button onClick={()=>this.setState({showError: false})}>Close</Button>
								</div>
							</div>
						</div>
						}
						<Link style={{textAlign: 'center', marginTop: 25}} href={'/register/complete/'}>Skip</Link>
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
	bank_pending: PropTypes.bool,
	error: PropTypes.string,
	resetBankState: PropTypes.string,
	linkBank: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		token: state.authState.accessToken,
		bank_pending: state.bankState.bank_pending,
		error: state.bankState.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		linkBank: (plaid_token, token, onSuccess, onErrorCallback) => dispatch(linkBank(plaid_token, token, onSuccess, onErrorCallback)),
		resetBankState: () => dispatch(resetBankState())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
